import React, { useMemo } from 'react';
import { format, isWithinInterval } from 'date-fns';
import { Copy } from 'lucide-react';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { toast } from 'sonner';

// Helper function to format data for copying
const formatDataForSpreadsheet = (entries, projectRates) => {
  if (!entries.length) return '';
  
  // Headers
  const headers = ['Date', 'User', 'Task', 'Notes', 'Hours', 'Billed'];
  
  // Format each entry
  const rows = entries.map(entry => {
    const hours = entry.duration / (1000 * 60 * 60);
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    const formattedHours = `${wholeHours}:${minutes.toString().padStart(2, '0')}`;
    const revenue = hours * (projectRates[entry.jira_project_key] || 0);
    
    return [
      format(new Date(entry.date), 'MMM d, yyyy'),
      entry.user_name || 'Unknown',
      entry.task,
      entry.task_description || '',
      formattedHours,
      `$${revenue.toFixed(2)}`
    ];
  });
  
  // Combine headers and rows
  return [headers, ...rows]
    .map(row => row.join('\t'))
    .join('\n');
};

const Timesheet = ({ entries, dateRange, onAddEntry, projectRates = {}, userRates = {} }) => {
  // Filter entries within the date range and calculate totals
  const { filteredEntries, totals } = useMemo(() => {
    if (!dateRange[0] || !dateRange[1] || !entries.length) {
      return { filteredEntries: [], totals: { workTime: 0, billed: 0, revenue: 0, cost: 0 } };
    }

    const filtered = entries
      .filter(entry => {
        const entryDate = new Date(entry.date);
        return isWithinInterval(entryDate, { start: dateRange[0], end: dateRange[1] });
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date ascending (oldest first)

    const totals = filtered.reduce((acc, entry) => {
      const hours = entry.duration / (1000 * 60 * 60); // Convert milliseconds to hours
      const rate = projectRates[entry.jira_project_key] || 0;
      const revenue = hours * rate;
      const userRate = userRates[entry.user_email] || 0;
      const cost = hours * userRate;
      
      return {
        workTime: acc.workTime + hours,
        billed: acc.billed + hours, // All hours are considered billed
        revenue: acc.revenue + revenue,
        cost: acc.cost + cost,
      };
    }, { workTime: 0, billed: 0, revenue: 0, cost: 0 });

    return { filteredEntries: filtered, totals };
  }, [entries, dateRange, projectRates, userRates]);

  // Group filtered entries by project
  const groupedEntries = filteredEntries.reduce((acc, entry) => {
    const projectKey = entry.jira_project_key;
    if (!acc[projectKey]) {
      acc[projectKey] = {
        projectName: entry.jira_project_name,
        projectKey: entry.jira_project_key,
        entries: [],
        totalHours: 0,
        totalRevenue: 0,
        latestEntry: new Date(entry.date) // Track the latest entry date for project sorting
      };
    }
    const hours = entry.duration / (1000 * 60 * 60);
    acc[projectKey].entries.push({
      ...entry,
      cost: hours * (userRates[entry.user_email] || 0)
    });
    // Update latest entry date if this entry is more recent
    if (new Date(entry.date) > acc[projectKey].latestEntry) {
      acc[projectKey].latestEntry = new Date(entry.date);
    }
    acc[projectKey].totalHours += hours;
    acc[projectKey].totalRevenue += hours * (projectRates[projectKey] || 0);
    return acc;
  }, {});

  // Sort entries within each project by oldest first
  Object.values(groupedEntries).forEach(project => {
    project.entries.sort((a, b) => new Date(a.date) - new Date(b.date));
  });

  // Convert to array and sort projects by most recent entry
  const sortedProjects = Object.entries(groupedEntries)
    .sort(([, a], [, b]) => b.latestEntry - a.latestEntry);

  // Format duration in hours and minutes
  const formatDuration = (hours) => {
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    return `${wholeHours}:${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <Card>
      <CardContent className="p-0 relative">
        <div className="max-h-[calc(100vh-13rem)] overflow-y-auto">
          <table className="w-full border-collapse">
            <thead className="sticky top-0 z-20 bg-background after:absolute after:left-0 after:right-0 after:bottom-0 after:border-b after:pointer-events-none">
              <tr>
                <th className="sticky left-0 z-30 bg-background text-left p-4 font-medium text-xs uppercase border border-border">Date</th>
                <th className="text-left p-4 font-medium text-xs uppercase border border-border">User</th>
                <th className="text-left p-4 font-medium text-xs uppercase border border-border">Project</th>
                <th className="text-left p-4 font-medium text-xs uppercase border border-border">Task</th>
                <th className="text-left p-4 font-medium text-xs uppercase border border-border">Notes</th>
                <th className="text-right p-4 font-medium text-xs uppercase border border-border">Hours</th>
                <th className="text-right p-4 font-medium text-xs uppercase border border-border">Billed</th>
              </tr>
            </thead>
            <tbody>
              {sortedProjects.map(([projectKey, project], index) => (
                <React.Fragment key={projectKey}>
                  {index > 0 && (
                    <tr>
                      <td colSpan="7" className="h-8 border border-border"></td>
                    </tr>
                  )}
                  <tr className="bg-muted/5">
                    <td colSpan="7" className="p-3 pl-4 border border-border">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="w-2 h-2 bg-primary rounded-full mr-2" />
                          <span className="font-medium">{project.projectName}</span>
                          {projectRates[project.projectKey] > 0 && (
                            <div className="flex items-center ml-8 text-sm">
                              <span className="font-medium">{formatDuration(project.totalHours)}</span>
                              <span className="mx-2 text-muted-foreground">·</span>
                              <span className="font-medium">${project.totalRevenue.toFixed(2)}</span>
                            </div>
                          )}
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          className="gap-2"
                          onClick={async () => {
                            const data = formatDataForSpreadsheet(project.entries, projectRates);
                            try {
                              await navigator.clipboard.writeText(data);
                              toast.success(`${project.projectName} timesheet copied to clipboard`);
                            } catch (err) {
                              toast.error('Failed to copy timesheet');
                              console.error('Copy failed:', err);
                            }
                          }}
                        >
                          <Copy className="h-4 w-4" />
                          Copy
                        </Button>
                      </div>
                    </td>
                  </tr>
                  {project.entries.map((entry) => {
                    const hours = entry.duration / (1000 * 60 * 60);
                    const revenue = hours * (projectRates[entry.jira_project_key] || 0);
                    const wholeHours = Math.floor(hours);
                    const minutes = Math.round((hours - wholeHours) * 60);
                    const formattedHours = `${wholeHours}:${minutes.toString().padStart(2, '0')}`;
                    return (
                      <tr key={entry.id} className="hover:bg-muted/5">
                        <td className="sticky left-0 bg-background p-4 border border-border">{format(new Date(entry.date), 'MMM d, yyyy')}</td>
                        <td className="p-4 border border-border">
                          <div>
                            <div>{entry.user_name || 'Unknown'}</div>
                            {userRates[entry.user_email] > 0 && (
                              <div className="text-xs text-muted-foreground">
                                ${userRates[entry.user_email]}/hr
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="p-4 border border-border">{entry.jira_project_name}</td>
                        <td className="p-4 border border-border">{entry.task}</td>
                        <td className="p-4 border border-border">{entry.task_description || '-'}</td>
                        <td className="p-4 text-right text-xs border border-border">{formattedHours}</td>
                        <td className="p-4 text-right text-xs border border-border">${revenue.toFixed(2)}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className="border-t flex items-center justify-between text-sm text-muted-foreground fixed bottom-0 left-[280px] right-0 bg-background z-50 shadow-md pl-8 pr-4 py-4">
          <div>
            <span className="font-medium">HOURS</span> <span className="text-lg font-medium text-foreground">{formatDuration(totals.workTime)}</span>
          </div>
          <div>
            <span className="font-medium">REVENUE</span> <span className="text-lg font-medium text-foreground">${totals.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
          </div>
          <div>
            <span className="font-medium">COST</span> <span className="text-lg font-medium text-foreground">${totals.cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Timesheet; 