import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './ui/table';

const ProjectSummaryTable = ({ projects, entries, projectRates, dateRange }) => {
  // Calculate totals with useMemo to update when dependencies change
  const totals = useMemo(() => {
    return projects.reduce((acc, project) => {
      const projectEntries = entries.filter(e => {
        const entryDate = new Date(e.date);
        return e.jira_project_key === project.key && 
               entryDate >= dateRange[0] && 
               entryDate <= dateRange[1];
      });

      const loggedHours = projectEntries.reduce((sum, entry) => 
        sum + (entry.duration / (1000 * 60 * 60)), 0
      );

      const realizedRevenue = loggedHours * (projectRates[project.key] || 0);
      const bookedHours = project.weekly_goal || 0;
      const bookedRevenue = bookedHours * (projectRates[project.key] || 0);

      return {
        loggedHours: acc.loggedHours + loggedHours,
        realizedRevenue: acc.realizedRevenue + realizedRevenue,
        bookedHours: acc.bookedHours + bookedHours,
        bookedRevenue: acc.bookedRevenue + bookedRevenue
      };
    }, { loggedHours: 0, realizedRevenue: 0, bookedHours: 0, bookedRevenue: 0 });
  }, [projects, entries, projectRates, dateRange]); // Add all dependencies

  return (
    <div className="fixed bottom-0 left-64 right-0 bg-background border-t shadow-lg">
      <div className="container py-2">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-xs font-medium uppercase">Total</TableHead>
              <TableHead className="text-right text-xs font-medium uppercase">Logged Hours</TableHead>
              <TableHead className="text-right text-xs font-medium uppercase">Realized Billed</TableHead>
              <TableHead className="text-right text-xs font-medium uppercase">Booked Hours</TableHead>
              <TableHead className="text-right text-xs font-medium uppercase">Booked Billed</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="text-xs font-medium">All Projects</TableCell>
              <TableCell className="text-right text-xs">{totals.loggedHours.toFixed(1)}h</TableCell>
              <TableCell className="text-right text-xs text-emerald-500">${totals.realizedRevenue.toFixed(2)}</TableCell>
              <TableCell className="text-right text-xs">{totals.bookedHours.toFixed(1)}h</TableCell>
              <TableCell className="text-right text-xs text-emerald-500">${totals.bookedRevenue.toFixed(2)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ProjectSummaryTable; 